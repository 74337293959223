import { Feature } from "../constants/feature";

export const TRANSLATION_CONST = {
  Title: "Translation",
  SubTitle: "Kissflow Translation",
  NotificationAndEmail: "Notifications and Emailers",
  Revision: ["Revision", "Date", "Added", "Updated", "Deleted"],
  HistoryTitle: "Translation Upload History",
  NoUploadHistory: "No History Available",
  BuildConfirm: "Do you want to build now?",
  SyncConfirm: "Please confirm if you would like to synchronize the folder",
  ProdConfirm: "Do you want to move to production?"
}

export const ROUTE_FEATURES = {
    ADMIN: [Feature.ADMIN, Feature.AUDIT_MANAGEMENT, Feature.READONLY_ADMIN],
    ADMIN_USER_MANAGEMENT: [Feature.ADMIN, Feature.AUDIT_MANAGEMENT, Feature.READONLY_ADMIN],
    ADMIN_ROLE_MANAGEMENT: [Feature.ADMIN, Feature.AUDIT_MANAGEMENT, Feature.READONLY_ADMIN],
    AUDIT: [Feature.ADMIN, Feature.AUDIT_MANAGEMENT, Feature.READONLY_ADMIN],
    ACTIVITY_LOG: [Feature.ADMIN, Feature.AUDIT_MANAGEMENT, Feature.READONLY_ADMIN],
    AUDIT_LOG: [Feature.ADMIN, Feature.AUDIT_MANAGEMENT, Feature.READONLY_ADMIN],
    MONGO: [Feature.MONGO, Feature.ADMIN_MONGO, Feature.ADMIN, Feature.COMMON_DB_MONGO, Feature.READONLY_ADMIN],
    MONGO_QUERY_LOG: [Feature.ADMIN, Feature.READONLY_ADMIN],
    MONGO_FILTER: [Feature.MONGO, Feature.ADMIN, Feature.READONLY_ADMIN],
    MONGO_ADMIN: [Feature.ADMIN, Feature.ADMIN_MONGO, Feature.READONLY_ADMIN],
    METADATA: [Feature.METADATA, Feature.ADMIN, Feature.READONLY_ADMIN],
    METADATA_ACCOUNT_FILTER: [Feature.METADATA, Feature.ADMIN, Feature.READONLY_ADMIN],
    SIRIUS: [Feature.SIRIUS, Feature.ADMIN, Feature.READONLY_ADMIN],
    TRANSLATION: [Feature.TRANSLATION_ADMIN, Feature.ADMIN, Feature.READONLY_ADMIN]
}

export const TRANSLATION_MESSAGES_FILE = "messages.json"
