import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthLayout } from "./auth.layout";
import { HomeLayout } from "./home.layout";

export class OrionLayout extends React.Component {
  public render() {
    return (
      <BrowserRouter basename="/view">
        <Switch>
          <Route path="/auth" exact component={AuthLayout} />
          <Route path="/" component={HomeLayout} />
        </Switch>
      </BrowserRouter>
    );
  }
}
