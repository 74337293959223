import React from "react";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  InteractionOutlined,
  AuditOutlined,
  ReloadOutlined,
  RetweetOutlined,
  SnippetsOutlined,
  TranslationOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { Feature } from "../constants/feature";
import { ROUTE_FEATURES } from "./constants";

export const HomeRoute = [
  {
    key: "home",
    name: "Home",
    path: "/",
    icon: HomeOutlined,
    features: [],
    exact: true,
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                webpackChunkName: "home-chunk", */
          "../components/home/home.component"
        )
    ),
  },
  // {
  //     key: "service_status",
  //     name: "Service Status",
  //     path: "/service/status",
  //     icon: ReloadOutlined,
  //     features: [Feature.SERVICE_STATUS, Feature.ADMIN],
  //     exact: true,
  //     component: React.lazy(
  //         () => import(
  //             /* webpackPreload: true,
  //             webpackChunkName: "service-status", */
  //             "../components/service/status.component"
  //         ))
  // },
  {
    key: "log",
    name: "Audit",
    path: "/audit",
    icon: InteractionOutlined,
    features: ROUTE_FEATURES.AUDIT,
    nonMenuLink: true,
    subMenu: true,
    subRoute: [
      {
        key: "activity",
        name: "Activity Log",
        path: "/log/activity",
        icon: UserSwitchOutlined,
        features: ROUTE_FEATURES.ACTIVITY_LOG,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "user-management", */
              "../components/audit/activity.log.component"
            )
        ),
      },
      {
        key: "audit",
        name: "Audit Log",
        path: "/log/audit",
        icon: AuditOutlined,
        features: ROUTE_FEATURES.AUDIT_LOG,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "role-management", */
              "../components/audit/audit.log.component"
            )
        ),
      },
    ],
  },
  {
    key: "admin",
    name: "Admin",
    path: "/admin",
    icon: TeamOutlined,
    features: ROUTE_FEATURES.ADMIN,
    nonMenuLink: true,
    subMenu: true,
    subRoute: [
      {
        key: "user",
        name: "User Management",
        path: "/admin/user",
        icon: UserSwitchOutlined,
        features: ROUTE_FEATURES.ADMIN_USER_MANAGEMENT,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "user-management", */
              "../components/admin/manage/user/user.management.component"
            )
        ),
      },
      {
        key: "role",
        name: "Role Management",
        path: "/admin/role",
        icon: UsergroupAddOutlined,
        features: ROUTE_FEATURES.ADMIN_ROLE_MANAGEMENT,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "role-management", */
              "../components/admin/manage/role/role.management.component"
            )
        ),
      },
    ],
  },
  {
    key: "mongo",
    name: "Mongo",
    path: "/access/mongo",
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                webpackChunkName: "mongo-home", */
          "../components/mongo/home.mongo.component"
        )
    ),
    exact: true,
    features: ROUTE_FEATURES.MONGO,
    icon: CodeOutlined,
    subMenu: false,
    subRoute: [
      {
        key: "mongo_query_log",
        name: "Mongo Query Log",
        path: "/mongo/query/log",
        features: ROUTE_FEATURES.MONGO_QUERY_LOG,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "mongo-query", */
              "../components/mongo/query_log.mongo.component"
            )
        ),
      },
      {
        key: "mongo_filter",
        name: "Mongo filter page",
        path: "/mongo/:db_type/:account_id",
        features: ROUTE_FEATURES.MONGO_FILTER,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                        webpackChunkName: "mongo-filter", */
              "../components/mongo/filter.mongo.component"
            )
        ),
      },
    ],
  },
  {
    key: "mongoadmin",
    name: "Mongo admin",
    path: "/access/mongo/admin",
    hide: true,
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                webpackChunkName: "mongo-home", */
          "../components/mongo/home.mongo.component"
        )
    ),
    exact: true,
    features: ROUTE_FEATURES.MONGO_ADMIN,
    icon: CodeOutlined,
    isAdmin: true,
  },
  {
    key: "metadata",
    name: "Metadata",
    path: "/access/metadata",
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                      webpackChunkName: "migration-status", */
          "../components/metadata/home.metadata.component"
        )
    ),
    exact: true,
    features: ROUTE_FEATURES.METADATA,
    icon: SnippetsOutlined,
    subMenu: false,
    subRoute: [
      {
        name: "Metadata Account Filter for application",
        path: "/metadata/:account_id",
        exact: true,
        features: ROUTE_FEATURES.METADATA_ACCOUNT_FILTER,
        component: React.lazy(
          () =>
            import(
              /* webpackPreload: true,
                      webpackChunkName: "migration-status", */
              "../components/metadata/view.metadata.component"
            )
        ),
      },
    ],
  },
  {
    key: "sirius",
    name: "Sirius",
    path: "/access/sirius",
    features: ROUTE_FEATURES.SIRIUS,
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                webpackChunkName: "sirius-chunk", */
          "../components/sirius/sirius.component"
        )
    ),
    exact: true,
    icon: RetweetOutlined,
  },
  {
    key: "translation",
    name: "Translation",
    path: "/translation",
    features: ROUTE_FEATURES.TRANSLATION,
    component: React.lazy(
      () =>
        import(
          /* webpackPreload: true,
                webpackChunkName: "sirius-chunk", */
          "../components/translation/home.translation.component"
        )
    ),
    exact: true,
    icon: TranslationOutlined,
  },

  //? uncomment below route for 3.0 Support
  // {
  //   key: "translation",
  //   name: "Translation",
  //   path: "/translation",
  //   features: [Feature.TRANSLATION_ADMIN, Feature.ADMIN],
  //   icon: TranslationOutlined,
  //   nonMenuLink: true,
  //   subMenu: true,
  //   subRoute: [
  //     {
  //       key: "4.0",
  //       name: "4.0",
  //       path: "/translation/4.0",
  //       icon: TranslationOutlined,
  //       exact: true,
  //       features: [Feature.TRANSLATION_ADMIN, Feature.ADMIN],
  //       component: React.lazy(
  //         () =>
  //           import(
  //             /* webpackPreload: true,
  //                   webpackChunkName: "translation-home", */
  //             "../components/translation/home.translation.component"
  //           )
  //       ),
  //     },
  //     {
  //       key: "3.0",
  //       name: "3.0",
  //       path: "/translation/3.0",
  //       icon: TranslationOutlined,
  //       exact: true,
  //       features: [Feature.TRANSLATION_ADMIN, Feature.ADMIN],
  //       component: React.lazy(
  //         () =>
  //           import(
  //             /* webpackPreload: true,
  //                   webpackChunkName: "translation-home", */
  //             "../components/translation/3.0.translation.component"
  //           )
  //       ),
  //     },
  //   ],
  // },
];
