export const Feature = {
    ADMIN: "admin",
    READONLY_ADMIN: "readonly_admin",
    USER_MANAGEMENT: "user_management",
    AUDIT_MANAGEMENT: "audit_management",
    ADMIN_MONGO: "admin_mongo",
    MONGO: "mongo",
    COMMON_DB_MONGO: "common_db_mongo",
    SIRIUS: "sirius",
    TRANSLATION_ADMIN: "translation_admin",
    TRANSLATION_UPLOAD: "translation_upload",
    TRANSLATION_MOVE_2_PRODUCTION: "translation_move_to_production",
    TRANSLATION_REVERT: "translation_revert",
    TRANSLATION_ADD_LANG: "translation_add_lang",
    METADATA: "metadata",
    MIGRATION: "migration",
    SERVICE_STATUS: "service_status"
}
